<template>
    <tr v-show="!item.isEdit" @click="openEditLine(index)">
        <td class="pl-10">
            <div class="col">
                <strong>
                    <h6>{{ item.name }}</h6>
                </strong>
                {{ item.desc }}
            </div>
        </td>

        <td class="text-center">{{ item.qty }}</td>
        <td class="text-center">{{ item.rate }}</td>
        <td class="text-center">{{ getSubTotal.toFixed(2) }}</td>
        <td class="text-center">{{ item.discount.toFixed(2) }}%</td>
        <td class="text-center">{{ getDiscountAmount.toFixed(2) }}</td>
        <td class="text-center">{{ getAmountAfterDiscount.toFixed(2) }}</td>
        <td class="text-center">{{ item.vat }}%</td>
        <td class="text-center">{{ getVatAmount.toFixed(2) }}</td>
        <td class="text-center">{{ getTotal.toFixed(2) }}</td>
        <td>
            <div>
                <button @click="deleteItem(index)" class="btn btn-outline-danger btn-sm">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>
    
    <tr v-show="item.isEdit" :class="item.isEdit && 'tr-box-shadow'">
        <td>
            <div class="td-inline">
                <v-select 
                    placeholder="Select item" class="w-100" 
                    :options="[]" label="name"
                    :reduce="(name) => name.id" 
                />
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.qty"
                    type="number" class="form-control"
                    placeholder="Qty" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.rate"
                    type="number" class="form-control"
                    placeholder="Rate" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    :value="getSubTotal" disabled
                    type="number" class="form-control"
                    placeholder="Rate" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    v-model.trim="item.discount"
                    type="number" class="form-control"
                    placeholder="Discount(%)" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    :value="getDiscountAmount" @input="setDiscountPrice"
                    type="number" class="form-control"
                    placeholder="Discount Amount" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    :value="getAmountAfterDiscount" disabled
                    type="number" class="form-control"
                    placeholder="Amount after discount" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <!-- <v-select 
                    placeholder="Select VAT percent" class="w-100" 
                    :options="vats" label="name" v-model="item.vat"
                    :reduce="(name) => name.id" 
                /> -->
                <input
                    v-model.trim="item.vat"
                    type="number" class="form-control"
                    placeholder="Vat(%)" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    :value="getVatAmount" disabled
                    type="number" class="form-control"
                    placeholder="Vat amount" :min="0"
                >
            </div>
        </td>
        <td>
            <div class="td-inline">
                <input
                    :value="getTotal" disabled
                    type="number" class="form-control"
                    placeholder="Vat amount" :min="0"
                >
            </div>
        </td>
        <td>
            <div>
                <button @click="deleteItem(index)" class="btn btn-outline-danger btn-sm">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>

</template>
<script setup>
    import { computed, ref } from "vue";
    import { formatNumberToTwoDecimals } from '@/services/utils/global';
    const props = defineProps(['item', 'index', 'onOpenEditItem', 'onDeleteItem', 'vats'])
    const emit = defineEmits(['onOpenEditItem', 'onDeleteItem']);

    
    const openEditLine = (i) => {
        emit('onOpenEditItem', i)
    }

    const deleteItem = (i) => {
        emit('onDeleteItem', i)
    }

    const getSubTotal = computed(() => {
        return (props.item.qty * props.item.rate)
    })

    const getDiscountAmount = computed(() => {
        let discountAmount = getSubTotal.value * (props.item.discount / 100);
        return Math.round(discountAmount);
    })

    const getAmountAfterDiscount = computed(() => {
        const total = getSubTotal.value;
        return total - (total * (props.item.discount / 100));
        
    })

    const getVatAmount = computed(() => {
        const vatAmount = getAmountAfterDiscount.value * (props.item.vat / 100);
        return vatAmount;
    })

    const getTotal = computed(() => {
        const total = getSubTotal.value - getDiscountAmount.value + getVatAmount.value;
        props.item.sub_total = getSubTotal.value;
        props.item.discount_amount= getDiscountAmount.value;
        props.item.vat_amount = getVatAmount.value;
        props.item.total = total;
        return total;
    })

    const setDiscountPrice = (e) => {
        let discountPrice = e.target.value;
        let percent = (discountPrice / getSubTotal.value) * 100;
        props.item.discount =  formatNumberToTwoDecimals(percent)
    }

</script>
<style scoped>

</style>